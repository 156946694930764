import React, { Component } from 'react';
import {
    CButton
} from '@coreui/react'

export class AfterLogin extends Component {

    render() {
        return (<div className='buttons' style={{ justifyContent: 'center', textAlign: 'center', marginTop: '30%' }}>

            <div style={{ marginBottom: '40px', justifyContent: 'space-between' }}>
                <CButton style={{ marginRight: '40px' }} color="primary" className="px-4" href='/servicerequest'>
                    Arıza Kaydı Oluştur
                </CButton>
                <CButton color="primary" className="px-4" href='/clientregister'>
                    Müşteri Kayıt
                </CButton>
            </div>
        </div>

        )
    }
}