import React, { Component } from 'react';

import {
    CAvatar,
    CButton,
    CButtonGroup,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CProgress,
    CRow
} from '@coreui/react'
import { CChartLine } from '@coreui/react-chartjs'
import {
    getStyle,
    //hexToRgba
} from '@coreui/utils'
import CIcon from '@coreui/icons-react'
import {
    //cibCcAmex,
    //cibCcApplePay,
    //cibCcMastercard,
    //cibCcPaypal,
    //cibCcStripe,
    //cibCcVisa,
    cibGoogle,
    cibFacebook,
    cibLinkedin,
    //cifBr,
    //cifEs,
    //cifFr,
    //cifIn,
    //cifPl,
    //cifUs,
    cibTwitter,
    cilCloudDownload,
    cilPeople,
    cilUser,
    cilUserFemale,
} from '@coreui/icons'

//import avatar1 from '../coreui/assets/images/avatars/1.jpg'
//import avatar2 from '../coreui/assets/images/avatars/2.jpg'
//import avatar3 from '../coreui/assets/images/avatars/3.jpg'
//import avatar4 from '../coreui/assets/images/avatars/4.jpg'
//import avatar5 from '../coreui/assets/images/avatars/5.jpg'
//import avatar6 from '../coreui/assets/images/avatars/6.jpg'

import WidgetsBrand from '../coreui/views/widgets/WidgetsBrand'
import WidgetsDropdown from '../coreui/views/widgets/WidgetsDropdown'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';

//import dayjs, { Dayjs } from 'dayjs';
//import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

//import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import './proposalform.css';

export class ProposalFormPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isPageLoad: false,
            customerSelectionId: -1,
            customerSelection: null,
            customersList: [],
            devicevendor: "",
            devicemodel: "",
            deviceserialnumber: "",
            requestdescription: "",
            deviceSerialNumbers: [],
            deviceDistinctSerialNumbers: [],
            deviceSelection: null,
            deviceSelectionId: -1,
            deviceserialnumtxt: "",
            StatusesList: [],
            statusSelection: null,
            searchresult: [],
            searchdatetime: "",
            isFetching: false,
            snackbarpos: false,
            snackbarmessage: "",
        };
    }

    componentDidMount = async () => {
        await this.fetchCustomers();

        await this.setState({
            isPageLoad: true
        });
    }

    random = (min, max) => {
        Math.floor(Math.random() * (max - min + 1) + min);
    }


    fetchCustomers = async (event) => {

        this.setState({
            isFetching: true,
        });

        var actionURL = "/api/TechSupport/FetchCustomers";

        await fetch(actionURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("FetchCustomers Succeeded");

                    this.setState({
                        customersList: result.queryresult,
                        isFetching: false,
                    });

                } else {
                    console.log("FetchCustomers Error");

                    this.setState({
                        isFetching: false,
                    });
                }
            }).catch(() => {
                console.log("FetchCustomers Error");

                this.setState({
                    isFetching: false,
                });
            });
    };

    handleChange = (e) => {
        //console.log(e.target.name + " " + e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            //console.log('Enter key pressed')
            if (!this.state.isFetching) {
                //this.loginAction(e);
            }
        }
    }


    handleCustomerChange = (e, v) => {
        e.persist();
        if (v !== undefined & v !== null) {
            this.setState({
                customerSelectionId: v.idLoginCredentials,
                customerSelection: v
            });

            this.searchServiceRequestWithCustomer(v.idLoginCredentials, v);
        } else {
            this.setState({
                customerSelectionId: -1,
                customerSelection: null
            });
        }
    }


    searchServiceRequestWithCustomer = async (customerSelectionId, customerSelection) => {
        //await event.preventDefault();

        this.setState({
            isFetching: true,
        });

        var actionURL = "/api/TechSupport/SearchServiceRequestWithCustomer";

        var statedata = this.state;
        statedata.customerSelectionId = customerSelectionId;
        statedata.customerSelection = customerSelection;

        await fetch(actionURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(statedata)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("searchServiceRequestWithCustomer Succeeded");

                    this.setState({
                        searchresult: result.queryresult,
                        isFetching: false,
                    });

                } else {
                    console.log("searchServiceRequestWithCustomer Error");

                    this.setState({
                        isFetching: false,
                    });
                }
            }).catch(() => {
                console.log("searchServiceRequestWithCustomer Error");

                this.setState({
                    isFetching: false,
                });
            });
    };



    serviceProposalDocumentationByCustomer = async () => {

        var getStatesURL = "/api/TechSupport/GenerateServiceProposalDocumentationByCustomer"

        this.setState({
            isFetching: true
        });

        await fetch(getStatesURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("serviceProposalDocumentationByCustomer Succeeded");

                    this.setState({
                        snackbarmessage: "Teklif formu oluşturuldu.",
                        snackbarpos: true,
                        isFetching: false,
                    });
                    this.handleSnackbarOpen();

                    console.log(result.filename);
                    window.location = "/api/TechSupport/DownloadFilename/t/" + result.filename;

                } else {
                    // Error
                    console.log("serviceProposalDocumentationByCustomer Error");

                    this.setState({
                        snackbarmessage: "Teklif formu oluşturulamadı.",
                        snackbarpos: true,
                        isFetching: false,
                    });
                    this.handleSnackbarOpen();
                }
            }).catch(() => {
                console.log("serviceProposalDocumentationByCustomer Error");

                this.setState({
                    snackbarmessage: "Teklif formu oluşturulamadı.",
                    snackbarpos: true,
                    isFetching: false,
                });
                this.handleSnackbarOpen();
            });
    }


    handleSnackbarOpen = async () => {
        //setOpen(true);

        this.setState({
            snackbarpos: true
        });
    };

    //handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    handleSnackbarClose = async () => {
        //if (reason === 'clickaway') {
        //    return;
        //}

        //setOpen(false);
        this.setState({
            snackbarpos: false
        });
    };







    render() {

        const {
            customersList,
            customerSelection,
            devicevendor,
            devicemodel,
            //deviceserialnumber,
            requestdescription,
            deviceSerialNumbers,
            deviceSelection,
            deviceserialnumtxt,
            StatusesList,
            statusSelection,
            searchresult,
            searchdatetime,
            isFetching,
            snackbarpos,
            snackbarmessage,
        } = this.state;

        if (!this.state.isPageLoad) {
            return (
                <div>
                    <p>Yükleniyor...</p>
                </div>
            );
        } else {

            return (
                <div className="body-box">

                    <div className="proposalform-searchbar-box">

                        <div className="customer-textbox-field">
                            <Autocomplete
                                options={customersList}
                                getOptionLabel={option => option.LoginName + " " + option.LoginSurname + " " + option.LoginPhoneNumber + " " + option.LoginTCID}
                                autoComplete
                                includeInputInList
                                onChange={this.handleCustomerChange.bind(this)}
                                renderInput={params => (
                                    <TextField {...params} label="Müşteri" margin="normal" fullWidth />
                                )}
                                value={customerSelection}
                                fullWidth
                                disabled={isFetching}
                            />
                        </div>

                        <div className="search-results-box">

                            {/* Table Section in a Card */}
                            <Box
                                component="main"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'light'
                                            ? theme.palette.grey[100]
                                            : theme.palette.grey[900],
                                    flexGrow: 1,
                                    //height: '100vh',
                                    overflow: 'auto',
                                    width: '100%',
                                }}
                            >
                                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                                    <Grid container spacing={3}>

                                        <Grid item xs={12}>
                                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                                <React.Fragment>
                                                    <p>Kayıtlar</p>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="left">Açıklama</TableCell>
                                                                <TableCell align="left">Seri No</TableCell>
                                                                <TableCell align="left">Marka</TableCell>
                                                                <TableCell align="left">Model</TableCell>
                                                                <TableCell align="left">Telefon</TableCell>
                                                                <TableCell align="left">İsim</TableCell>
                                                                <TableCell align="left">Soyad</TableCell>
                                                                {/*<TableCell align="right">&nbsp;</TableCell>*/}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {searchresult.map((row) => (
                                                                <TableRow key={"S" + row.idServiceRequest + "C" + row.idLoginCredentials}>
                                                                    <TableCell align="left">{row.ServiceRequestDescription}</TableCell>
                                                                    <TableCell align="left">{row.DeviceSerialNumber}</TableCell>
                                                                    <TableCell align="left">{row.DeviceVendor}</TableCell>
                                                                    <TableCell align="left">{row.DeviceProductName}</TableCell>
                                                                    <TableCell align="left">{row.LoginPhoneNumber}</TableCell>
                                                                    <TableCell align="left">{row.LoginName}</TableCell>
                                                                    <TableCell align="left">{row.LoginSurname}</TableCell>
                                                                    {/*<TableCell align="right">*/}
                                                                    {/*    <button className="btn btn-primary"*/}
                                                                    {/*        onClick={() => { window.location = "/statusupdate/" + row.idServiceRequest }}*/}
                                                                    {/*    >*/}
                                                                    {/*        Durum Güncelle*/}
                                                                    {/*    </button>*/}
                                                                    {/*</TableCell>*/}
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </React.Fragment>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Box>

                        </div>

                        <div className="proposal-button" >
                            <Button variant="contained" color="primary"
                                onClick={this.serviceProposalDocumentationByCustomer.bind(this)}
                                disabled={isFetching ? true : false}> Teklif Formu </Button>
                        </div>

                    </div>



                    <Snackbar
                        open={snackbarpos}
                        autoHideDuration={6000}
                        onClose={this.handleSnackbarClose.bind(this)}
                        message={snackbarmessage}
                        action={(
                            <React.Fragment>
                                <Button color="secondary" size="small" onClick={this.handleSnackbarClose.bind(this)}>
                                    Close
                                </Button>
                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={this.handleSnackbarClose.bind(this)}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        )}
                    />

                </div>
            );

        }
    }
}



