import React, { Component } from 'react';

import {
    CAvatar,
    CButton,
    CButtonGroup,
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CProgress,
    CRow
} from '@coreui/react'
import { CChartLine } from '@coreui/react-chartjs'
import {
    getStyle,
    //hexToRgba
} from '@coreui/utils'
import CIcon from '@coreui/icons-react'
import {
    //cibCcAmex,
    //cibCcApplePay,
    //cibCcMastercard,
    //cibCcPaypal,
    //cibCcStripe,
    //cibCcVisa,
    cibGoogle,
    cibFacebook,
    cibLinkedin,
    //cifBr,
    //cifEs,
    //cifFr,
    //cifIn,
    //cifPl,
    //cifUs,
    cibTwitter,
    cilCloudDownload,
    cilPeople,
    cilUser,
    cilUserFemale,
} from '@coreui/icons'

//import avatar1 from '../coreui/assets/images/avatars/1.jpg'
//import avatar2 from '../coreui/assets/images/avatars/2.jpg'
//import avatar3 from '../coreui/assets/images/avatars/3.jpg'
//import avatar4 from '../coreui/assets/images/avatars/4.jpg'
//import avatar5 from '../coreui/assets/images/avatars/5.jpg'
//import avatar6 from '../coreui/assets/images/avatars/6.jpg'

import WidgetsBrand from '../coreui/views/widgets/WidgetsBrand'
import WidgetsDropdown from '../coreui/views/widgets/WidgetsDropdown'

export class DashboardPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            progressExample: [
                { title: 'Visits', value: '29.703 Users', percent: 40, color: 'success' },
                { title: 'Unique', value: '24.093 Users', percent: 20, color: 'info' },
                { title: 'Pageviews', value: '78.706 Views', percent: 60, color: 'warning' },
                { title: 'New Users', value: '22.123 Users', percent: 80, color: 'danger' },
                { title: 'Bounce Rate', value: 'Average Rate', percent: 40.15, color: 'primary' },
            ],

            progressGroupExample1: [
                { title: 'Monday', value1: 34, value2: 78 },
                { title: 'Tuesday', value1: 56, value2: 94 },
                { title: 'Wednesday', value1: 12, value2: 67 },
                { title: 'Thursday', value1: 43, value2: 91 },
                { title: 'Friday', value1: 22, value2: 73 },
                { title: 'Saturday', value1: 53, value2: 82 },
                { title: 'Sunday', value1: 9, value2: 69 },
            ],

            progressGroupExample2: [
                { title: 'Male', icon: cilUser, value: 53 },
                { title: 'Female', icon: cilUserFemale, value: 43 },
            ],

            progressGroupExample3: [
                { title: 'Organic Search', icon: cibGoogle, percent: 56, value: '191,235' },
                { title: 'Facebook', icon: cibFacebook, percent: 15, value: '51,223' },
                { title: 'Twitter', icon: cibTwitter, percent: 11, value: '37,564' },
                { title: 'LinkedIn', icon: cibLinkedin, percent: 8, value: '27,319' },
            ],
        };
    }

    async componentDidMount() {

    }


    random = (min, max) => {
        Math.floor(Math.random() * (max - min + 1) + min);
    }

    render() {

        const {
            progressExample,
            progressGroupExample1,
            progressGroupExample2,
            progressGroupExample3,
        } = this.state

        return (
            <>


            </>
        );
    }
}
