import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CFormTextarea,
    CInputGroup,
    CInputGroupText,
    CRow,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilUser } from '@coreui/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import './servicerequest.css';

export class ServiceRequest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPageLoad: false,
            customerSelectionId: -1,
            customerSelection: null,
            customersList: [],
            devicevendor: "",
            devicemodel: "",
            deviceserialnumber: "",
            requestdescription: "",
            deviceSerialNumbers: [],
            deviceDistinctSerialNumbers: [],
            deviceSelection: null,
            deviceSelectionId: -1,
            deviceserialnumtxt: "",
            deviceaccessories: "",
            snackbarpos: false,
        };
    }

    componentDidMount = async () => {
        await this.fetchCustomers();
        await this.fetchDeviceSerialNumbers();

        await this.setState({
            isPageLoad: true
        });
    }

    fetchCustomers = async (event) => {

        var actionURL = "/api/TechSupport/FetchCustomers";

        await fetch(actionURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("FetchCustomers Succeeded");

                    this.setState({
                        customersList: result.queryresult
                    });

                } else {
                    console.log("FetchCustomers Error");
                }
            }).catch(() => {
                console.log("FetchCustomers Error");
            });
    };

    fetchDeviceSerialNumbers = async (event) => {

        var actionURL = "/api/TechSupport/GetDeviceSerialNumbers";

        await fetch(actionURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("GetDeviceSerialNumbers Succeeded");

                    this.setState({
                        deviceSerialNumbers: result.listOfDeviceSerialNumber
                    });

                } else {
                    console.log("GetDeviceSerialNumbers Error");
                }
            }).catch(() => {
                console.log("GetDeviceSerialNumbers Error");
            });
    };

    fetchDistinctDeviceSerialNumbers = async (event) => {

        var actionURL = "/api/TechSupport/GetDistinctDeviceSerialNumber";

        await fetch(actionURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("GetDistinctDeviceSerialNumber Succeeded");

                    this.setState({
                        deviceDistinctSerialNumbers: result.listOfDeviceSerialNumber
                    });

                } else {
                    console.log("GetDistinctDeviceSerialNumber Error");
                }
            }).catch(() => {
                console.log("GetDistinctDeviceSerialNumber Error");
            });
    };

    handleChange = (e) => {
        //console.log(e.target.name + " " + e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            //console.log('Enter key pressed')
            if (!this.state.isFetching) {
                //this.loginAction(e);
            }
        }
    }

    createServiceRequest = async (event) => {
        await event.preventDefault();

        var actionURL = "/api/TechSupport/CreateServiceRequest";

        await fetch(actionURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("CreateServiceRequest Succeeded");

                    this.handleSnackbarOpen();
                } else {
                    console.log("CreateServiceRequest Error");
                }
            }).catch(() => {
                console.log("CreateServiceRequest Error");
            });
    };

    handleCustomerChange = (e, v) => {
        e.persist();
        if (v !== undefined & v !== null) {
            this.setState({
                customerSelectionId: v.idLoginCredentials,
                customerSelection: v
            });
        } else {
            this.setState({
                customerSelectionId: -1,
                customerSelection: null
            });
        }
    }

    handleDeviceChange = (e, v) => {
        e.persist();

        if (v !== undefined & v !== null) {
            this.setState({
                deviceSelectionId: v.idDeviceDetails,
                deviceSelection: v,
                devicemodel: v.DeviceProductName,
                devicevendor: v.DeviceVendor,
                deviceserialnumber: v.DeviceSerialNumber,
            });
        } else {
            this.setState({
                deviceSelectionId: -1,
                deviceSelection: null,
                devicemodel: "",
                devicevendor: "",
                deviceserialnumber: "",
            });
        }
    }

    handleSnackbarOpen = async () => {
        //setOpen(true);

        this.setState({
            snackbarpos: true
        });
    };

    //handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    handleSnackbarClose = async () => {
        //if (reason === 'clickaway') {
        //    return;
        //}

        //setOpen(false);
        this.setState({
            snackbarpos: false
        });
    };

    render() {

        const {
            customersList,
            customerSelection,
            devicevendor,
            devicemodel,
            //deviceserialnumber,
            requestdescription,
            deviceSerialNumbers,
            deviceSelection,
            deviceserialnumtxt,
            deviceaccessories,
            snackbarpos,
        } = this.state;

        if (!this.state.isPageLoad) {
            return (
                <div>
                    <p>Yükleniyor...</p>
                </div>
            );
        } else {
            return (
                < div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center" >
                    <CContainer>
                        <CRow className="justify-content-center">
                            <CCol md={8}>
                                <CCardGroup>
                                    <CCard className="p-4">
                                        <CCardBody>
                                            <CForm>
                                                <h1>Servis Kaydı</h1>
                                                <p className="text-body-secondary">Yeni Müşterinin Bilgilerini Giriniz</p>
                                                <CInputGroup className="mb-3 customer-input-group-override">
                                                    <CInputGroupText className="customer-input-grouptext" style={{ paddingRight: '4px' }}>
                                                        Müşteri
                                                    </CInputGroupText>
                                                    <Autocomplete
                                                        options={customersList}
                                                        getOptionLabel={option => option.LoginName + " " + option.LoginSurname + " " + option.LoginPhoneNumber + " " + option.LoginTCID}
                                                        autoComplete
                                                        includeInputInList
                                                        onChange={this.handleCustomerChange.bind(this)}
                                                        renderInput={params => (
                                                            <TextField {...params}
                                                                className="customer-input-textfield"
                                                                label="Müşteri" margin="normal" fullWidth />
                                                        )}
                                                        value={customerSelection}
                                                        fullWidth
                                                        className="customer-autocomplete-div"
                                                    />
                                                </CInputGroup>
                                                <CInputGroup className="mb-3">
                                                    <CInputGroupText style={{ paddingRight: '13px' }} >
                                                        Marka
                                                    </CInputGroupText>
                                                    <CFormInput
                                                        placeholder="Ürün Markası"
                                                        autoComplete="username"
                                                        value={devicevendor}
                                                        onChange={this.handleChange.bind(this)}
                                                        name="devicevendor"
                                                        onKeyDown={this.handleKeyDown.bind(this)}
                                                    />
                                                </CInputGroup>
                                                <CInputGroup className="mb-3">
                                                    <CInputGroupText style={{ paddingRight: '13px' }} >
                                                        Model
                                                    </CInputGroupText>
                                                    <CFormInput
                                                        placeholder="Ürün Modeli"
                                                        autoComplete="username"
                                                        value={devicemodel}
                                                        onChange={this.handleChange.bind(this)}
                                                        name="devicemodel"
                                                        onKeyDown={this.handleKeyDown.bind(this)}
                                                    />
                                                </CInputGroup>
                                                <CInputGroup className="mb-3 serialnumber-input-group-override">
                                                    <CInputGroupText className="serialnumber-input-grouptext" style={{ paddingRight: '5px' }}>
                                                        Seri No
                                                    </CInputGroupText>
                                                    <Autocomplete
                                                        options={deviceSerialNumbers}
                                                        getOptionLabel={option => option.DeviceSerialNumber + " " + option.DeviceVendor + " " + option.DeviceProductName}
                                                        autoComplete
                                                        includeInputInList
                                                        onChange={this.handleDeviceChange.bind(this)}
                                                        renderInput={params => (
                                                            <TextField {...params}
                                                                label="Seri No" margin="normal" fullWidth
                                                                value={deviceserialnumtxt}
                                                                onChange={this.handleChange.bind(this)}
                                                                name="deviceserialnumtxt"
                                                                className="serialnumber-input-textfield"
                                                            />
                                                        )}
                                                        value={deviceSelection}
                                                        fullWidth
                                                        freeSolo
                                                        className="serialnumber-autocomplete-div"
                                                    />
                                                </CInputGroup>
                                                <CInputGroup className="mb-3 defect-input-group-override">
                                                    <CInputGroupText style={{ paddingRight: '16px' }}>
                                                        Arıza
                                                    </CInputGroupText >
                                                    <CFormTextarea
                                                        placeholder="Arıza kaydını giriniz."
                                                        autoComplete="username"
                                                        type='ri'
                                                        value={requestdescription}
                                                        onChange={this.handleChange.bind(this)}
                                                        name="requestdescription"
                                                        onKeyDown={this.handleKeyDown.bind(this)}
                                                    />
                                                </CInputGroup>
                                                <CInputGroup className="mb-3 accessories-input-group-override">
                                                    <CInputGroupText style={{ paddingRight: '16px' }}>
                                                        Aksesuar
                                                    </CInputGroupText >
                                                    <CFormTextarea
                                                        placeholder="Aksesuarları buraya yazınız."
                                                        autoComplete="username"
                                                        type='ri'
                                                        value={deviceaccessories}
                                                        onChange={this.handleChange.bind(this)}
                                                        name="deviceaccessories"
                                                        onKeyDown={this.handleKeyDown.bind(this)}
                                                    />
                                                </CInputGroup>

                                                <CRow >
                                                    <CCol xs={6} >
                                                        <CButton
                                                            color="primary"
                                                            className="px-4"
                                                            onClick={this.createServiceRequest.bind(this)}
                                                        >
                                                            Kaydet
                                                        </CButton>
                                                    </CCol>
                                                </CRow>
                                            </CForm>
                                        </CCardBody>
                                    </CCard>
                                </CCardGroup>
                            </CCol>
                        </CRow>
                    </CContainer>


                    <Snackbar
                        open={snackbarpos}
                        autoHideDuration={6000}
                        onClose={this.handleSnackbarClose.bind(this)}
                        message="Arıza kaydı kayıt edildi."
                        action={(
                            <React.Fragment>
                                <Button color="secondary" size="small" onClick={this.handleSnackbarClose.bind(this)}>
                                    Close
                                </Button>
                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={this.handleSnackbarClose.bind(this)}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        )}
                    />
                </div >
            )
        }
    }
}

