import React, { Component } from 'react';
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
    CFormTextarea,
    CTable,
    CTableBody,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableDataCell,
} from '@coreui/react';
import withRouter from '../components/withRouter';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import './statusupdate.css';

class StatusUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            isPageLoad: false,
            servicerequestid: 0,
            servicerequestdetails: [],
            StatusesList: [],
            statusSelection: null,
            servicerequestupdatedescription: "",
            EmployeesList: [],
            employeeSelection: null,
            snackbarpos: false,
            snackbarmessage: "",
            isFetching: false,
        };
    }

    componentDidMount = async () => {
        await this.setState({
            servicerequestid: this.props.params.servicerequestid,
        });

        await this.getStatesByServiceRequestId();
        await this.fetchStatusesList();
        await this.fetchEmployeesList();

        await this.setState({
            isPageLoad: true
        });
    }


    fetchStatusesList = async (event) => {

        var actionURL = "/api/TechSupport/FetchStatusesList";

        await fetch(actionURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("FetchStatusesList Succeeded");

                    this.setState({
                        StatusesList: result.listOfStatuses
                    });

                } else {
                    console.log("FetchStatusesList Error");
                }
            }).catch(() => {
                console.log("FetchStatusesList Error");
            });
    };


    fetchEmployeesList = async (event) => {

        var actionURL = "/api/TechSupport/FetchEmployees";

        await fetch(actionURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("FetchStatusesList Succeeded");

                    this.setState({
                        EmployeesList: result.queryresult
                    });

                } else {
                    console.log("FetchStatusesList Error");
                }
            }).catch(() => {
                console.log("FetchStatusesList Error");
            });
    };


    getStatesByServiceRequestId = async () => {

        var getStatesURL = "/api/TechSupport/GetStatesByServiceRequestId"

        await fetch(getStatesURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    this.setState({
                        servicerequestdetails: result.servicerequestdetails
                    });
                } else {
                    // Error
                }
            });
    }



    addNewServiceUpdate = async () => {

        var getStatesURL = "/api/TechSupport/InsertServiceRequestDetails"

        this.setState({
            isFetching: true
        });

        await fetch(getStatesURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("addNewServiceUpdate Succeeded");

                    this.getStatesByServiceRequestId();

                    this.setState({
                        servicerequestupdatedescription: "",
                        snackbarmessage: "Arıza kaydı güncellendi.",
                        snackbarpos: true,
                        isFetching: false,
                    });
                    this.handleSnackbarOpen();

                } else {
                    // Error
                    console.log("addNewServiceUpdate Error");

                    this.setState({
                        servicerequestupdatedescription: "",
                        snackbarmessage: "Arıza kaydı güncellenemedi.",
                        snackbarpos: true,
                        isFetching: false,
                    });
                    this.handleSnackbarOpen();
                }
            }).catch(() => {
                console.log("addNewServiceUpdate Error");

                this.setState({
                    snackbarmessage: "Arıza kaydı güncellenemedi.",
                    snackbarpos: true,
                    isFetching: false,
                });
                this.handleSnackbarOpen();
            });
    }


    serviceRequestDocumentation = async () => {

        var getStatesURL = "/api/TechSupport/GenerateServiceRequestDocumentation"

        this.setState({
            isFetching: true
        });

        await fetch(getStatesURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("serviceRequestDocumentation Succeeded");

                    this.setState({
                        snackbarmessage: "Servis kaydı formu oluşturuldu.",
                        snackbarpos: true,
                        isFetching: false,
                    });
                    this.handleSnackbarOpen();

                    console.log(result.filename);
                    window.location = "/api/TechSupport/DownloadFilename/t/" + result.filename;

                } else {
                    // Error
                    console.log("serviceRequestDocumentation Error");

                    this.setState({
                        snackbarmessage: "Servis kaydı formu oluşturulamadı.",
                        snackbarpos: true,
                        isFetching: false,
                    });
                    this.handleSnackbarOpen();
                }
            }).catch(() => {
                console.log("serviceRequestDocumentation Error");

                this.setState({
                    snackbarmessage: "Servis kaydı formu oluşturulamadı.",
                    snackbarpos: true,
                    isFetching: false,
                });
                this.handleSnackbarOpen();
            });
    }


    serviceProposalDocumentation = async () => {

        var getStatesURL = "/api/TechSupport/GenerateServiceProposalDocumentation"

        this.setState({
            isFetching: true
        });

        await fetch(getStatesURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("serviceProposalDocumentation Succeeded");

                    this.setState({
                        snackbarmessage: "Teklif formu oluşturuldu.",
                        snackbarpos: true,
                        isFetching: false,
                    });
                    this.handleSnackbarOpen();

                    console.log(result.filename);
                    window.location = "/api/TechSupport/DownloadFilename/t/" + result.filename;

                } else {
                    // Error
                    console.log("serviceProposalDocumentation Error");

                    this.setState({
                        snackbarmessage: "Teklif formu oluşturulamadı.",
                        snackbarpos: true,
                        isFetching: false,
                    });
                    this.handleSnackbarOpen();
                }
            }).catch(() => {
                console.log("serviceProposalDocumentation Error");

                this.setState({
                    snackbarmessage: "Teklif formu oluşturulamadı.",
                    snackbarpos: true,
                    isFetching: false,
                });
                this.handleSnackbarOpen();
            });
    }


    serviceCompletionDocumentation = async () => {

        var getStatesURL = "/api/TechSupport/GenerateServiceCompletionDocumentation"

        this.setState({
            isFetching: true
        });

        await fetch(getStatesURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("serviceCompletionDocumentation Succeeded");

                    this.setState({
                        snackbarmessage: "Teslim formu oluşturuldu.",
                        snackbarpos: true,
                        isFetching: false,
                    });

                    console.log(result.filename);
                    window.location = "/api/TechSupport/DownloadFilename/t/" + result.filename;

                } else {
                    // Error
                    console.log("serviceCompletionDocumentation Error");

                    this.setState({
                        snackbarmessage: "Teslim formu oluşturulamadı.",
                        snackbarpos: true,
                        isFetching: false,
                    });
                    this.handleSnackbarOpen();
                }
            }).catch(() => {
                console.log("serviceCompletionDocumentation Error");

                this.setState({
                    snackbarmessage: "Teslim formu oluşturulamadı.",
                    snackbarpos: true,
                    isFetching: false,
                });
                this.handleSnackbarOpen();
            });
    }


    handleChange = (e) => {
        //console.log(e.target.name + " " + e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            //console.log('Enter key pressed')
            if (!this.state.isFetching) {
                //this.loginAction(e);
            }
        }
    }






    handleStatusChange = (e, v) => {
        e.persist();

        if (v !== undefined & v !== null) {
            this.setState({
                statusSelection: v
            });
        } else {
            this.setState({
                statusSelection: null,
            });
        }
    }



    handleEmployeeChange = (e, v) => {
        e.persist();

        if (v !== undefined & v !== null) {
            this.setState({
                employeeSelection: v
            });
        } else {
            this.setState({
                employeeSelection: null,
            });
        }
    }


    getNameForStatus = (statusid) => {
        return this.state.StatusesList.find(item => { return item.Id === statusid }).Name;
    }

    getNameForEmployee = (employeeid) => {

        console.log("employeeid");
        console.log(employeeid);

        if (employeeid === 0) {
            return "";
        }

        var vEmployee = this.state.EmployeesList.find(item => { return item.idLoginCredentials === employeeid });

        console.log(vEmployee);

        var vEmployeeName = vEmployee.LoginName + " " + vEmployee.LoginSurname;

        console.log(vEmployeeName);

        return vEmployeeName;
    }

    handleSnackbarOpen = async () => {
        //setOpen(true);

        this.setState({
            snackbarpos: true
        });
    };

    //handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    handleSnackbarClose = async () => {
        //if (reason === 'clickaway') {
        //    return;
        //}

        //setOpen(false);
        this.setState({
            snackbarpos: false
        });
    };



    render() {

        const {
            servicerequestid,
            servicerequestdetails,
            StatusesList,
            statusSelection,
            servicerequestupdatedescription,
            EmployeesList,
            employeeSelection,
            isFetching,
            snackbarpos,
            snackbarmessage,
        } = this.state;

        if (!this.state.isPageLoad) {
            return (
                <div>
                    <p>Yükleniyor...</p>
                </div>
            );
        } else {

            return (
                <>
                    <CContainer>
                        <CRow className="justify-content-center">

                            <CCol md="6">
                                <CCardGroup>
                                    <CCard className="p-4">
                                        <CCardBody>
                                            <CForm>
                                                {/*<h3>Ürün Bilgileri</h3>*/}
                                                {/*<p>Satıcı: </p>*/}
                                                {/*<p>Model: </p>*/}
                                                {/*<p>Mevcut Durum:</p>*/}
                                                {/*<p>Kullanıcı:</p>*/}

                                                <CInputGroup className="mb-3 status-input-group-override" >
                                                    <CInputGroupText className="status-input-grouptext" style={{ width: '110px' }}>Yeni Durum</CInputGroupText>
                                                    <Autocomplete
                                                        options={StatusesList}
                                                        getOptionLabel={option => option.Name}
                                                        autoComplete
                                                        includeInputInList
                                                        onChange={this.handleStatusChange.bind(this)}
                                                        renderInput={params => (
                                                            <TextField {...params} className="status-input-textfield" label="Durum" margin="normal" fullWidth />
                                                        )}
                                                        value={statusSelection}
                                                        fullWidth
                                                        className="status-autocomplete-div "
                                                    />
                                                </CInputGroup>

                                                <CInputGroup className="mb-3 employee-input-group-override" >
                                                    <CInputGroupText className="employee-input-grouptext " style={{ width: '110px' }}>Atanacak</CInputGroupText>
                                                    <Autocomplete
                                                        options={EmployeesList}
                                                        getOptionLabel={option => option.LoginName + " " + option.LoginSurname}
                                                        autoComplete
                                                        includeInputInList
                                                        onChange={this.handleEmployeeChange.bind(this)}
                                                        renderInput={params => (
                                                            <TextField {...params} className="employee-input-textfield" label="Kullanıcı" margin="normal" fullWidth />
                                                        )}
                                                        value={employeeSelection}
                                                        fullWidth
                                                        disabled={statusSelection !== null && statusSelection.Id === 10 ? false : true}
                                                        className="employee-autocomplete-div"
                                                    />
                                                </CInputGroup>

                                                <CInputGroup className="mb-3">
                                                    <CInputGroupText style={{ width: '110px' }}>Açıklama</CInputGroupText>
                                                    <CFormTextarea
                                                        placeholder="Açıklama giriniz"
                                                        rows="3"
                                                        value={servicerequestupdatedescription}
                                                        onChange={this.handleChange.bind(this)}
                                                        name="servicerequestupdatedescription"
                                                        onKeyDown={this.handleKeyDown.bind(this)}
                                                    />
                                                </CInputGroup>

                                                <div align="center">
                                                    <CButton
                                                        style={{ position: 'relative' }}
                                                        color="primary"
                                                        disabled={isFetching}
                                                        onClick={this.addNewServiceUpdate.bind(this)}>Durumu Güncelle</CButton>
                                                </div>
                                            </CForm>
                                        </CCardBody>
                                    </CCard>
                                </CCardGroup>
                            </CCol>

                        </CRow>

                        <CRow className="justify-content-center">

                            <CCol md="12" className="mt-4">
                                <Box
                                    component="main"
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[100]
                                                : theme.palette.grey[900],
                                        flexGrow: 1,
                                        //height: '100vh',
                                        overflow: 'auto',
                                        width: '100%',
                                    }}
                                >
                                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

                                        <CTable striped hover responsive>
                                            <CTableHead>
                                                <CTableRow>
                                                    <CTableHeaderCell>Tarih</CTableHeaderCell>
                                                    <CTableHeaderCell>Durum</CTableHeaderCell>
                                                    <CTableHeaderCell>Açıklama/Atama</CTableHeaderCell>
                                                    <CTableHeaderCell>Girişi Yapan</CTableHeaderCell>
                                                </CTableRow>
                                            </CTableHead>
                                            <CTableBody>
                                                {servicerequestdetails.map((item, index) => (
                                                    <CTableRow key={item.idServiceRequestDetails}>
                                                        <CTableDataCell>{item.DetailDate}</CTableDataCell>
                                                        <CTableDataCell>{this.getNameForStatus(item.ServiceRequestState)}</CTableDataCell>
                                                        <CTableDataCell>{item.ServiceRequestState === 10 ? this.getNameForEmployee(item.AssignedTo) : item.ServiceRequestDetailsDescription}</CTableDataCell>
                                                        <CTableDataCell>{item.LoginName + " " + item.LoginSurname}</CTableDataCell>
                                                    </CTableRow>
                                                ))}
                                            </CTableBody>
                                        </CTable>

                                    </Container>
                                </Box>

                            </CCol>


                        </CRow>
                    </CContainer>

                    <Grid container className="bottom-buttons-box">
                        <Grid item>
                            <Button variant="contained" color="primary"
                                onClick={this.serviceRequestDocumentation.bind(this)}
                                disabled={isFetching ? true : false}> Kayıt Fişi </Button>
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <Button variant="contained" color="primary"*/}
                        {/*        onClick={this.serviceProposalDocumentation.bind(this)}*/}
                        {/*        disabled={isFetching ? true : false}> Teklif Formu </Button>*/}
                        {/*</Grid>*/}
                        <Grid item>
                            <Button variant="contained" color="primary"
                                onClick={this.serviceCompletionDocumentation.bind(this)}
                                disabled={isFetching ? true : false}> Teslim Fişi </Button>
                        </Grid>
                    </Grid>



                    <Snackbar
                        open={snackbarpos}
                        autoHideDuration={6000}
                        onClose={this.handleSnackbarClose.bind(this)}
                        message={snackbarmessage}
                        action={(
                            <React.Fragment>
                                <Button color="secondary" size="small" onClick={this.handleSnackbarClose.bind(this)}>
                                    Close
                                </Button>
                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={this.handleSnackbarClose.bind(this)}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        )}
                    />

                </>
            );
        }
    }
}

export default withRouter(StatusUpdate);
