import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,

} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


export class ClientRegister extends Component {

    constructor(props) {
        super(props);

        this.state = {
            form_user_name: "",
            form_user_surname: "",
            form_user_address: "",
            form_user_tcnumber: "",
            form_user_email: "",
            form_user_phonenumber: "",
            snackbarpos: false,
            snackbarmessage: "",
        };
    }

    componentDidMount = async () => {

    }

    handleChange = (e) => {
        //console.log(e.target.name + " " + e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            //console.log('Enter key pressed')
            if (!this.state.isFetching) {
                //this.loginAction(e);
            }
        }
    }

    createUser = async (event) => {
        await event.preventDefault();

        this.setState({
            isFetching: true,
        });

        var actionURL = "/api/TechSupport/CreateCustomer";

        await fetch(actionURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("CreateCustomer Succeeded");

                    this.setState({
                        snackbarmessage: "Müşteri kayıt edildi.",
                        snackbarpos: true,
                        isFetching:false,
                    });
                    this.handleSnackbarOpen();

                } else {
                    console.log("CreateCustomer Error");

                    this.setState({
                        snackbarmessage: "Müşteri kayıt edilemedi.",
                        snackbarpos: true,
                        isFetching: false,
                    });
                    this.handleSnackbarOpen();

                }
            }).catch(() => {
                console.log("CreateCustomer Error");

                this.setState({
                    snackbarmessage: "Müşteri kayıt edilemedi.",
                    snackbarpos: true,
                    isFetching: false,
                });
                this.handleSnackbarOpen();
            });
    };

    handleSnackbarOpen = async () => {
        //setOpen(true);

        this.setState({
            snackbarpos: true
        });
    };

    //handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    handleSnackbarClose = async () => {
        //if (reason === 'clickaway') {
        //    return;
        //}

        //setOpen(false);
        this.setState({
            snackbarpos: false
        });
    };



    render() {

        const {
            form_user_name,
            form_user_surname,
            form_user_address,
            form_user_tcnumber,
            form_user_email,
            form_user_phonenumber,
            snackbarpos,
            snackbarmessage,
            isFetching,
        } = this.state;

        return (
            < div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center" >
                <CContainer>
                    <CRow className="justify-content-center">
                        <CCol md={8}>
                            <CCardGroup>
                                <CCard className="p-4">
                                    <CCardBody>
                                        <CForm>
                                            <h1>Yeni Müşteri Kaydı</h1>
                                            <p className="text-body-secondary">Yeni Müşterinin Bilgilerini Giriniz</p>
                                            <CInputGroup className="mb-3">
                                                <CInputGroupText style={{ paddingRight: '40px' }}>
                                                    İsim
                                                </CInputGroupText>
                                                <CFormInput
                                                    placeholder="İsim"
                                                    autoComplete="username"
                                                    value={form_user_name}
                                                    onChange={this.handleChange.bind(this)}
                                                    name="form_user_name"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                />
                                            </CInputGroup>
                                            <CInputGroup className="mb-3">
                                                <CInputGroupText style={{ paddingRight: '14px' }}>
                                                    Soyisim
                                                </CInputGroupText>
                                                <CFormInput
                                                    placeholder="Soyisim"
                                                    autoComplete="username"
                                                    value={form_user_surname}
                                                    onChange={this.handleChange.bind(this)}
                                                    name="form_user_surname"
                                                    onKeyDown={this.handleKeyDown.bind(this)} />
                                            </CInputGroup>
                                            <CInputGroup className="mb-3">
                                                <CInputGroupText style={{ paddingRight: '28px' }}>
                                                    Adres
                                                </CInputGroupText>
                                                <CFormInput
                                                    placeholder="Adres"
                                                    autoComplete="username"
                                                    value={form_user_address}
                                                    onChange={this.handleChange.bind(this)}
                                                    name="form_user_address"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                />
                                            </CInputGroup>
                                            <CInputGroup className="mb-3">
                                                <CInputGroupText style={{ paddingRight: '24px' }}>
                                                    TC No
                                                </CInputGroupText>
                                                <CFormInput
                                                    placeholder="Tc Kimlik Numarası"
                                                    autoComplete="username"
                                                    value={form_user_tcnumber}
                                                    onChange={this.handleChange.bind(this)}
                                                    name="form_user_tcnumber"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                />
                                            </CInputGroup>
                                            <CInputGroup className="mb-3">
                                                <CInputGroupText style={{ paddingRight: '12px' }}>
                                                    E-Posta
                                                </CInputGroupText>
                                                <CFormInput
                                                    placeholder="techsupport@gmail.com"
                                                    autoComplete="username" type='email'
                                                    value={form_user_email}
                                                    onChange={this.handleChange.bind(this)}
                                                    name="form_user_email"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                />
                                            </CInputGroup>
                                            <CInputGroup className="mb-3">
                                                <CInputGroupText style={{ paddingRight: '16px', }}>
                                                    Telefon
                                                </CInputGroupText>
                                                <CFormInput
                                                    placeholder="0(5xx)xxxxxxx"
                                                    autoComplete="username"
                                                    value={form_user_phonenumber}
                                                    onChange={this.handleChange.bind(this)}
                                                    name="form_user_phonenumber"
                                                    onKeyDown={this.handleKeyDown.bind(this)}
                                                />
                                            </CInputGroup>

                                            <CRow>
                                                <CCol xs={6}>
                                                    {/*<Link to="/afterlogin">*/}
                                                    <CButton
                                                        color="primary"
                                                        className="px-4"
                                                        disabled={isFetching}
                                                        onClick={this.createUser.bind(this)}>
                                                        Kayıt
                                                    </CButton>
                                                    {/*</Link>*/}
                                                </CCol>
                                            </CRow>
                                        </CForm>
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>
                        </CCol>
                    </CRow>
                </CContainer>

                <Snackbar
                    open={snackbarpos}
                    autoHideDuration={6000}
                    onClose={this.handleSnackbarClose.bind(this)}
                    message={snackbarmessage}
                    action={(
                        <React.Fragment>
                            <Button color="secondary" size="small" onClick={this.handleSnackbarClose.bind(this)}>
                                Close
                            </Button>
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={this.handleSnackbarClose.bind(this)}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    )}
                />



            </div >
        )
    }
}