import React, { Component } from 'react';

import Grid from '@mui/material/Grid';
//import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
//import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
//import FormControlLabel from '@mui/material/FormControlLabel';

import './main.css';

export class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputUsername: "",
            inputPassword: "",
            rememberMe: false,
            title: "Sign In",
            isFetching: false,
            loginMessage: "",
        };

        //localStorage.removeItem("authtoken");
    }

    handleChange = (e) => {
        //console.log(e.target.name + " " + e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            //console.log('Enter key pressed')
            if (!this.state.isFetching) {
                this.loginAction(e);
            }
        }
    }

    componentDidMount() {

    }


    loginAction = async (event) => {
        event.preventDefault();

        await this.setState({ isFetching: true });

        //localStorage.removeItem("authtoken");

        var postFormData = new FormData();
        postFormData.append("operation", "Auth");

        //var postData = this.state.formdatas;
        var postData = this.state;
        Object.keys(postData).forEach((key) => {
            postFormData.append(key, postData[key]);
        });

        //let localauthtok = localStorage.getItem("authtoken");

        var uri = "/api/AuthAPI/Login";

        await fetch(uri, {
            method: "POST",
            body: postFormData
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0 &&
                    result.authresp === 1) {
                    localStorage.setItem("authtoken", result.authtoken);
                    window.location.href = "/dashboard";
                    //window.location.href = "/projectlist";
                } else if (result.resultstate === 0 &&
                    result.authresp === 2) {
                    localStorage.setItem("authtoken", result.authtoken);
                    // Check for Token Authentication Again
                    window.location.href = "/dashboard";
                } else {
                    if (result.authresp === 3 || result.authresp === 4) {
                        this.setState({ loginMessage: "Incorrect Username or Password" });
                    } else {
                        window.location.href = "/login";
                    }
                }
            }).catch(() => {
                window.location.href = "/login";
            });

        await this.setState({ isFetching: false });
    };


    checkTokenAction() {

        let pageTokenAuthData = {
            operation: "AuthCheckToken"
        };

        var uri = "/api/AuthAPI/TokenAuth";

        fetch(uri, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(pageTokenAuthData)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate > 0 ||
                    result.authresp === 2) {

                    localStorage.removeItem("authtoken");

                    window.location.href = "/login";
                }
            }).catch(() => {
                localStorage.removeItem("authtoken");

                window.location.href = "/login";
            });
    }



    render() {

        const {
            inputUsername,
            inputPassword,
            loginMessage,
            isFetching,
        } = this.state;

        return (

            <>

                <Grid container id="grid-container-1" direction="row" justify="center" alignItems="center" >

                    <div id="card-container">
                        <CardHeader id="login-card-header" title="Enter your email to sign-up or login." />
                        <CardContent>
                            <Typography id="login-card-content-1"
                                variant="subtitle1" gutterBottom>
                                Enter your email to create your account. You will sign in to your account if you already have one.
                            </Typography>
                            <p id="login-message">{loginMessage}</p>
                            <Grid item>
                                <TextField
                                    id="input-email"
                                    label="E-mail Adress:"
                                    placeholder="Enter email"
                                    margin="normal" fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={inputUsername}
                                    onChange={this.handleChange.bind(this)}
                                    name="inputUsername"
                                    onKeyDown={this.handleKeyDown.bind(this)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="input-password"
                                    label="Password"
                                    placeholder="Password"
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={inputPassword}
                                    onChange={this.handleChange.bind(this)}
                                    name="inputPassword"
                                    type="password"
                                    onKeyDown={this.handleKeyDown.bind(this)}
                                />
                            </Grid>
                            {/*<Grid item>*/}
                            {/*    <FormControlLabel control={<Checkbox name="rememberMe"*/}
                            {/*        value={rememberMe} checked={rememberMe}*/}
                            {/*        onChange={this.handleChange.bind(this)} />} label="Remember Me" />*/}
                            {/*</Grid>*/}
                            {/*<Grid item>*/}
                            {/*    <a href="/forgotpassword">Forgot Password</a>*/}
                            {/*</Grid>*/}
                            <Grid item style={{ textAlign: "center" }}>
                                <Button id="login-submit-button" variant="contained" color="primary"
                                    onClick={this.loginAction.bind(this)}
                                    disabled={isFetching ? true : false}> Sign In </Button>
                            </Grid>
                            <div id="login-accept-conditions-text">
                                <p>
                                    By using this service, you agree to be bound by Techsup's Terms of Use.
                                </p>
                            </div>
                        </CardContent>
                    </div>

                </Grid>



            </>
        )

    }
}


