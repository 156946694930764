//import { Counter } from "./components/Counter";
//import { FetchData } from "./components/FetchData";
//import { Home } from "./components/Home";
import { Dash1 } from "./pages/Dash1";
import { Login } from "./pages/Login";
import { AfterLogin } from "./pages/AfterLogin";
import { ClientRegister } from "./pages/ClientRegister";
import { ServiceRequest } from "./pages/ServiceRequest";
import StatusUpdate from './pages/StatusUpdate';
import { Status } from './pages/Status';
import { Container } from 'reactstrap';
import { NavMenu } from './components/NavMenu';
import { DashboardPage } from "./pages/DashboardPage";
import { EmployeeRegister } from "./pages/EmployeeRegister";
import { ProposalFormPage } from "./pages/ProposalFormPage";

const AppRoutes = [
    {
        index: true,
        element: <Login />
    },
    {
        path: '/dashboard',
        element: <><NavMenu /><Container><DashboardPage /></Container></>
    },
    {
        path: '/dash1',
        element: <><NavMenu /><Container><Dash1 /></Container></>
    },
    {
        path: '/login',
        element: <><NavMenu /><Container><Dash1 /></Container></>
    },
    {
        path: '/afterlogin',
        element: <><NavMenu /><Container><AfterLogin /></Container></>
    },
    {
        path: '/clientregister',
        element: <><NavMenu /><Container><ClientRegister /></Container></>
    },
    {
        path: '/employeeregister',
        element: <><NavMenu /><Container><EmployeeRegister /></Container></>
    },
    {
        path: '/servicerequest',
        element: <><NavMenu /><Container><ServiceRequest /></Container></>
    },
    {
        path: '/statusupdate/:servicerequestid',
        element: <><NavMenu /><Container><StatusUpdate /></Container></>
    },
    {
        path: '/status',
        element: <><NavMenu /><Container><Status /></Container></>
    },
    {
        path: '/proposalform',
        element: <><NavMenu /><Container><ProposalFormPage /></Container></>
    }

];

export default AppRoutes;
