import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CRow
} from '@coreui/react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';

//import dayjs, { Dayjs } from 'dayjs';
//import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import './status.css';

export class Status extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isPageLoad: false,
            customerSelectionId: -1,
            customerSelection: null,
            customersList: [],
            devicevendor: "",
            devicemodel: "",
            deviceserialnumber: "",
            requestdescription: "",
            deviceSerialNumbers: [],
            deviceDistinctSerialNumbers: [],
            deviceSelection: null,
            deviceSelectionId: -1,
            deviceserialnumtxt: "",
            StatusesList: [],
            statusSelection: null,
            searchresult: [],
            searchdatetime: "",
        };
    }

    componentDidMount = async () => {
        await this.fetchCustomers();
        await this.fetchDeviceSerialNumbers();
        await this.fetchStatusesList();

        await this.setState({
            isPageLoad: true
        });
    }

    fetchCustomers = async (event) => {

        var actionURL = "/api/TechSupport/FetchCustomers";

        await fetch(actionURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("FetchCustomers Succeeded");

                    this.setState({
                        customersList: result.queryresult
                    });

                } else {
                    console.log("FetchCustomers Error");
                }
            }).catch(() => {
                console.log("FetchCustomers Error");
            });
    };

    fetchDeviceSerialNumbers = async (event) => {

        var actionURL = "/api/TechSupport/GetDeviceSerialNumbers";

        await fetch(actionURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("GetDeviceSerialNumbers Succeeded");

                    this.setState({
                        deviceSerialNumbers: result.listOfDeviceSerialNumber
                    });

                } else {
                    console.log("GetDeviceSerialNumbers Error");
                }
            }).catch(() => {
                console.log("GetDeviceSerialNumbers Error");
            });
    };

    fetchDistinctDeviceSerialNumbers = async (event) => {

        var actionURL = "/api/TechSupport/GetDistinctDeviceSerialNumber";

        await fetch(actionURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("GetDistinctDeviceSerialNumber Succeeded");

                    this.setState({
                        deviceDistinctSerialNumbers: result.listOfDeviceSerialNumber
                    });

                } else {
                    console.log("GetDistinctDeviceSerialNumber Error");
                }
            }).catch(() => {
                console.log("GetDistinctDeviceSerialNumber Error");
            });
    };

    fetchStatusesList = async (event) => {

        var actionURL = "/api/TechSupport/FetchStatusesList";

        await fetch(actionURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("FetchStatusesList Succeeded");

                    this.setState({
                        StatusesList: result.listOfStatuses
                    });

                } else {
                    console.log("FetchStatusesList Error");
                }
            }).catch(() => {
                console.log("FetchStatusesList Error");
            });
    };

    handleChange = (e) => {
        //console.log(e.target.name + " " + e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            //console.log('Enter key pressed')
            if (!this.state.isFetching) {
                //this.loginAction(e);
            }
        }
    }

    searchServiceRequest = async (event) => {
        await event.preventDefault();

        var actionURL = "/api/TechSupport/SearchServiceRequest";

        await fetch(actionURL, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("authtoken"),
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
        })
            .then(response => response.json())
            .then(result => {
                if (result.resultstate === 0) {
                    console.log("SearchServiceRequest Succeeded");

                    this.setState({
                        searchresult: result.queryresult
                    });

                } else {
                    console.log("SearchServiceRequest Error");
                }
            }).catch(() => {
                console.log("SearchServiceRequest Error");
            });
    };

    handleCustomerChange = (e, v) => {
        e.persist();
        if (v !== undefined & v !== null) {
            this.setState({
                customerSelectionId: v.idLoginCredentials,
                customerSelection: v
            });
        } else {
            this.setState({
                customerSelectionId: -1,
                customerSelection: null
            });
        }
    }

    handleDeviceChange = (e, v) => {
        e.persist();

        if (v !== undefined & v !== null) {
            this.setState({
                deviceSelectionId: v.idDeviceDetails,
                deviceSelection: v,
                devicemodel: v.DeviceProductName,
                devicevendor: v.DeviceVendor,
                deviceserialnumber: v.DeviceSerialNumber,
            });
        } else {
            this.setState({
                deviceSelectionId: -1,
                deviceSelection: null,
                devicemodel: "",
                devicevendor: "",
                deviceserialnumber: "",
            });
        }
    }


    handleStatusChange = (e, v) => {
        e.persist();

        if (v !== undefined & v !== null) {
            this.setState({
                statusSelection: v
            });
        } else {
            this.setState({
                statusSelection: null,
            });
        }
    }



    render() {



        const {
            customersList,
            customerSelection,
            devicevendor,
            devicemodel,
            //deviceserialnumber,
            requestdescription,
            deviceSerialNumbers,
            deviceSelection,
            deviceserialnumtxt,
            StatusesList,
            statusSelection,
            searchresult,
            searchdatetime,
        } = this.state;

        if (!this.state.isPageLoad) {
            return (
                <div>
                    <p>Yükleniyor...</p>
                </div>
            );
        } else {

            return (
                <div className="body-box">

                    <div className="status-searchbar-box">

                        <div className="customer-textbox-field">
                            <Autocomplete
                                options={customersList}
                                getOptionLabel={option => option.LoginName + " " + option.LoginSurname + " " + option.LoginPhoneNumber + " " + option.LoginTCID}
                                autoComplete
                                includeInputInList
                                onChange={this.handleCustomerChange.bind(this)}
                                renderInput={params => (
                                    <TextField {...params} label="Müşteri" margin="normal" fullWidth />
                                )}
                                value={customerSelection}
                                fullWidth
                            />
                        </div>

                        <div className="device-textbox-field">
                            <Autocomplete
                                options={deviceSerialNumbers}
                                getOptionLabel={option => option.DeviceSerialNumber + " " + option.DeviceVendor + " " + option.DeviceProductName}
                                autoComplete
                                includeInputInList
                                onChange={this.handleDeviceChange.bind(this)}
                                renderInput={params => (
                                    <TextField {...params}
                                        label="Seri No" margin="normal" fullWidth
                                        value={deviceserialnumtxt}
                                        onChange={this.handleChange.bind(this)}
                                        name="deviceserialnumtxt"
                                    />
                                )}
                                value={deviceSelection}
                                fullWidth
                            />
                        </div>

                        <div className="status-textbox-field">
                            <Autocomplete
                                options={StatusesList}
                                getOptionLabel={option => option.Name}
                                autoComplete
                                includeInputInList
                                onChange={this.handleStatusChange.bind(this)}
                                renderInput={params => (
                                    <TextField {...params} label="Durum" margin="normal" fullWidth />
                                )}
                                value={statusSelection}
                                fullWidth
                            />
                        </div>

                        <div className="date-select-field">
                            {/*<TextField label="Tarih" margin="normal" fullWidth placeholder="GG.AA.YYYY" />*/}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {/*<DatePicker*/}
                                {/*    label="Controlled picker"*/}
                                {/*    value={searchdatetime}*/}
                                {/*    onChange={(newValue) => { this.setState({ searchdatetime: newValue }) }}*/}
                                {/*/>*/}
                                <DatePicker />
                            </LocalizationProvider>
                        </div>

                        <div className="search-button-field">
                            <Button color="primary"
                                variant="contained"
                                onClick={this.searchServiceRequest.bind(this)}>
                                Ara
                            </Button>
                        </div>

                    </div>

                    <div className="search-results-box">

                        {/* Table Section in a Card */}
                        <Box
                            component="main"
                            sx={{
                                backgroundColor: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? theme.palette.grey[100]
                                        : theme.palette.grey[900],
                                flexGrow: 1,
                                //height: '100vh',
                                overflow: 'auto',
                                width: '100%',
                            }}
                        >
                            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <React.Fragment>
                                                <p>Kayıtlar</p>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left">Açıklama</TableCell>
                                                            <TableCell align="left">Seri No</TableCell>
                                                            <TableCell align="left">Marka</TableCell>
                                                            <TableCell align="left">Model</TableCell>
                                                            <TableCell align="left">Telefon</TableCell>
                                                            <TableCell align="left">İsim</TableCell>
                                                            <TableCell align="left">Soyad</TableCell>
                                                            <TableCell align="right">&nbsp;</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {searchresult.map((row) => (
                                                            <TableRow key={"S" + row.idServiceRequest + "C" + row.idLoginCredentials}>
                                                                <TableCell align="left">{row.ServiceRequestDescription}</TableCell>
                                                                <TableCell align="left">{row.DeviceSerialNumber}</TableCell>
                                                                <TableCell align="left">{row.DeviceVendor}</TableCell>
                                                                <TableCell align="left">{row.DeviceProductName}</TableCell>
                                                                <TableCell align="left">{row.LoginPhoneNumber}</TableCell>
                                                                <TableCell align="left">{row.LoginName}</TableCell>
                                                                <TableCell align="left">{row.LoginSurname}</TableCell>
                                                                <TableCell align="right">
                                                                    <button className="btn btn-primary"
                                                                        onClick={() => { window.location = "/statusupdate/" + row.idServiceRequest }}
                                                                    >
                                                                        Durum Güncelle
                                                                    </button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </React.Fragment>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>

                    </div>

                </div>

            );

        }
    }
}
